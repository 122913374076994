export const colors = {
  green: {
    50: '#F2FFE8',
    100: '#E3FFCD',
    200: '#D7FABC',
    300: '#B8E994',
    400: '#97E78A',
    500: '#78E08F',
    600: '#4EA031',
    700: '#36801C',
    800: '#285C15',
    900: '#234F13',
  },
  gray: {
    50: '#F6F6F6',
    100: '#F0F4E7',
    200: '#ECF0E3',
    300: '#E7EDDC',
    400: '#D7E7DA',
    500: '#C8D7CB',
    600: '#9AA49C',
    700: '#697667',
    800: '#505A4F',
    900: '#394138',
  },
  yellow: '#FFE493',
  red: '#DC3545',
  redLight: '#FFF8F8',
};
